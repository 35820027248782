import React, { Component } from "react";
import Slide from "react-reveal/Slide";

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const work = this.props.data.work.map((work) => {
      var workImage = "images/portfolio/" + work.image;
      return (
        <Slide left duration={1300} key={work.company}>
          <div className="dropdownmenu">
            <a href={work.site} target="_blank" rel="noreferrer" className={work.site ? "has-link" : ""}>
              <img src={work.image} src={workImage} alt={work.company} />
              <h3>{work.company}</h3>
            </a>
            <p className="info">
              {work.title}
              <span>&bull;</span> <em className="date">{work.years}</em>
            </p>
            <p>{work.description}</p>
          </div>
        </Slide>
      );
    });

    const education = this.props.data.education.map((education) => {
      var educationImage = "images/portfolio/" + education.image;
      return (
        <Slide left duration={1300} key={education.school}>
          <div>
            <a href={education.site} target="_blank" rel="noreferrer" className={education.site ? "has-link" : ""}>
              <img src={education.image} src={educationImage} alt={education.school} />
              <h3>{education.school}</h3>
            </a>
            <p className="info">{education.degree}
            </p>
            <p>{education.description}</p>
          </div>
        </Slide>
      );
    });

    return (
      <section id="resume">
        <div className="row work">
          <Slide left duration={1300} fraction={0.1}>
            <div className="three columns header-col">
              <h1>
                <span>Work</span>
              </h1>
            </div>
          </Slide>

          <div className="nine columns main-col">{work}</div>
        </div>

        <div className="row education">
          <Slide left duration={1300} fraction={0.1}>
            <div className="three columns header-col">
              <h1>
                <span>Education</span>
              </h1>
            </div>
          </Slide>

          <div className="nine columns main-col">{education}</div>
        </div>
      </section>
    );
  }
}

export default Resume;
