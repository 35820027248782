import React, { Component } from "react";
import Slide from "react-reveal/Slide";

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSectionVisible: false,
    };

    this.scrollToPortfolio = this.scrollToPortfolio.bind(this); // Bind the function
  }

  handleScroll() {
    const scrollY = window.scrollY || window.pageYOffset;
    const offset = 300;

    if (!this.state.isSectionVisible && scrollY + window.innerHeight > offset) {
      this.setState({ isSectionVisible: true });
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  scrollToPortfolio(event) {
    event.preventDefault();
    const targetElement = document.querySelector('#portfolio');
    if (targetElement) {
      const offset = targetElement.offsetTop - 20; // Adjust the offset as needed
      window.scrollTo({ top: offset, behavior: 'smooth' });
    }
  }

  render() {
    if (this.props.data) {
      var projects = this.props.data.projects.map(function (projects) {
        var projectImage = "images/portfolio/" + projects.image;
        return (
          <Slide left duration={1300} key={projects.title}>
            <div className="columns portfolio-item">
              <div className="item-wrap">
                <a href={projects.url} title={projects.title}>
                  <img alt={projects.title} src={projectImage} />
                  <div className="overlay">
                    <div className="portfolio-item-meta">
                      <h5>{projects.title}</h5>
                      <p>{projects.category}</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Slide>
        );
      });
    }

    return (
      <section id="portfolio">
        <div className="projects">
          <Slide left duration={1300} fraction={0.1} when={this.state.isSectionVisible}>
            <div className="row">
              <div className="twelve columns">
                <h1>
                  <a href="https://projects.ibrahimsaid.ca/"><span>Projects</span></a>
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="twelve columns collapsed">
                <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                  {projects}
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </section>
    );  
  }
}

export default Portfolio;